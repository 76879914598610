<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card> -->
    <!-- 搜索-->
    <el-row>
      <!-- <el-col>
          <el-button
            plain
            class="add-column-btn bbox"
            @click="gotoDetail('')"
            type="primary"
          >
            <i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
        </el-col> -->
      <el-col :span="4">
        <div class="flex align-center justify-end header-search-box">
          <el-input
            placeholder="请输入用户电话"
            clearable
            v-model="searchInput"
            class="header-search-input"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="textblue searchBtn"
              @click="search()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <!-- 用户列表区域 -->
    <el-table
      :data="tableData"
      border
      fit
      :height="height"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column
        label="编号"
        align="center"
        type="index"
        width="80px"
        prop=""
      >
      </el-table-column>
      <el-table-column label="微信昵称" align="center" prop="用户名称">
        <template slot-scope="scope">
          <span>{{ scope.row.memberName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="微信头像" align="center" prop="用户头像">
        <template slot-scope="scope">
          <el-avatar
            :src="scope.row.logo"
            shape="square"
            :size="60"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="手机号" align="center" prop="手机号">
        <template slot-scope="scope">
          <span>{{ scope.row.phone ? scope.row.phone : "---" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="身份证号" align="center" prop="身份证号">
        <template slot-scope="scope">
          <span>{{ scope.row.idCard ? scope.row.idCard : "---" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="是否是VIP" align="center" prop="是否是VIP">
          <template slot-scope="scope">
            <span>{{ scope.row.isVip == 1 ? "会员" : "非会员" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="vip到期时间" align="center" prop="vip到期时间">
          <template slot-scope="scope">
            <span v-if="scope.row.limitDt">{{
              scope.row.limitDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column> -->
      <el-table-column label="创建时间" align="center" prop="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createDt">{{
            scope.row.createDt | dataFormat
          }}</span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="vip类型" align="center" prop="vip类型">
          <template slot-scope="scope">
            <span v-if="scope.row.vipType == ''">未开通</span>
            <span v-if="scope.row.vipType == 1">月卡</span>
            <span v-if="scope.row.vipType == 2">季卡</span>
            <span v-if="scope.row.vipType == 3">年卡</span>
          </template>
        </el-table-column> -->
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="goMamberOrderList(scope.row.memberCode)"
            >查看行程</el-button
          >
          <el-popconfirm
            title="确定禁用该用户吗？"
            v-if="scope.row.status == '1'"
            @onConfirm="toDisable('2', scope.row.id)"
          >
            <el-button type="text" slot="reference">禁用</el-button>
          </el-popconfirm>
          <el-popconfirm
            title="确定解禁该用户吗？"
            v-if="scope.row.status == '2'"
            @onConfirm="toDisable('1', scope.row.id)"
          >
            <el-button type="text" slot="reference">解禁</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="footerBox">
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.currPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      searchInput: "",
      tableData: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {}
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: ""
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false
    };
  },
  created() {
    // this.queryInfo.currPage = this.$route.query.currPage;
    // this.queryInfo.pageSize = this.$route.query.pageSize;
    if (this.$route.query.queryInfo) {
      this.queryInfo = JSON.parse(this.$route.query.queryInfo);
    }
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //用户禁用or解禁
    toDisable(status, id) {
      this.$http
        .post("/member/update", {
          status,
          id
        })
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("操作成功！");
            this.getListData();
          }
        });
    },
    //跳转用户订单
    goMamberOrderList(code) {
      this.$router.push({
        path: "/memberOrderList",
        query: { memberCode: code, queryInfo: JSON.stringify(this.queryInfo) }
      });
    },
    //条件查询
    search() {
      this.queryInfo.condition.keyWord = this.searchInput;
      this.getListData();
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/member/details",
        query: {
          id: id,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize
        }
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/member/delete", { id: id }).then(function(response) {
          that.$notify.success({
            title: "提示",
            message: "删除成功",
            showClose: true
          });
          that.getListData();
        });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      that.$http.post("/member/list2", that.queryInfo).then(function(response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage;

          that.tableData = response.data.data.data.map(v => {
            v.memberName = decodeURI(v.memberName);
            return v;
          });
        }
      });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    .header-search-box .header-search-input {
      width: 325px;
    }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
